import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Multi-Theme`}</h2>
    <p>{`Legion uses multi-theming with the help of `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` from `}<a parentName="p" {...{
        "href": "https://emotion.sh/docs/theming#themeprovider-reactcomponenttype"
      }}>{`Emotion`}</a>{`, this means you can switch themes quickly and see what you need with as easy as props parsing to the `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{`. With each theme from each tribe being fetched from an API endpoint, you are guaranteed to be updated as long as both designer and developer cooperated with any incoming updates to your application.`}</p>
    <br />
    <br />
    <h3><strong parentName="h3">{`ThemeProvider`}</strong></h3>
    <p>{`In this example we are going to use what’s generated from the `}<a parentName="p" {...{
        "href": "https://create-react-app.dev/"
      }}>{`Create React App`}</a>{` command, but the principal stays the same across entire application. You are going to edit your main root file of your React Js whether it’s `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` or `}<inlineCode parentName="p">{`Index.js`}</inlineCode>{` or `}<inlineCode parentName="p">{`Main.js`}</inlineCode>{`, and wrap the entire Main Component / Main App with the `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` such as follows with the props description:`}</p>
    <br />
    <br />
    <h3><strong parentName="h3">{`Props`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`theme`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The generated object from designer’s Figma pushed to the given repository. Fetched first before passed to the `}<inlineCode parentName="td">{`ThemeProvider`}</inlineCode>{` as props.`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <h3><strong parentName="h3">{`Available Themes`}</strong></h3>
    <p>{`Not all squad are also provided like tribe, some are ready for use some aren’t since needs will differ across platforms too. Here are the squads that are ready to use by order:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Theme Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`API Endpoint`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme Pijar Sekolah`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/pjs.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme Logee Trans`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/lgt.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme Agree Culture`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/agr.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme GovMart`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/gtp.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme BigBox`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/bbx.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme TKP`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/tkp.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme DigiTern`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/int.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme OSS`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/oss.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme PadiUMKM`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/pdm.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme MyTens`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/mtn.json`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme BPOM`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`https://api.github.com/repos/telkom-design/theme/contents/bpm.json`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <h3><strong parentName="h3">{`Usage Themes`}</strong></h3>
    <p>{`Below is the usage example using Pijar’s squad theme.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "filename=\"index.js\"",
        "filename": "\"index.js\""
      }}>{`import { ThemeProvider } from '@emotion/react'
import { createTokens } from '@legion-ui/core'

const TokensPijar = createTokens({
  apiUrl: 'https://api.github.com/repos/telkom-design/theme/contents/pjs.json',
  apiHeaders: { Accept: 'application/vnd.github.v3.raw' },
  tokensName: 'pijarTokens'
})

const themePijar = {
  name: 'Pijar',
  tokens: TokensPijar
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={themePijar}>
      <AppRoute />
    </ThemeProvider>
  </React.StrictMode>,
)
`}</code></pre>
    <p>{`After you wrap the main `}<inlineCode parentName="p">{`App`}</inlineCode>{` component inside the `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{`, you’re all set and ready to go you can use any available components provided.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      